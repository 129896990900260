import React from "react"
import SEO from "../../components/seo"
import Header from "../../components/header"

import WorkFooter from "../../components/portfolioItems/worksFooter"


import Herospace from "../../../assets/images/portfolio_items/sfi/sfi.png"
import sfi1 from "../../../assets/images/portfolio_items/sfi/sfi1.png"
import sfi2 from "../../../assets/images/portfolio_items/sfi/sfi2.png"
import sfi3 from "../../../assets/images/portfolio_items/sfi/sfi3.png"

export default () => 
<div>
    <SEO title={'Swiss ICON Worldwide | Swiss Finance Institute - Drupal Development Institute'} 
    description="An engaging Drupal website development for the Swiss Finance Institute: discover more on ICON's portfolio!"
    canonical={'https://icon-worldwide.com/works/swiss-finance-institute'} />
        <Header bgcolor="#292929"/>
    <div id="portfolio-item">
        <div className="herospace">
            <img src={Herospace} alt="Swiss Finance Institute, ICON Worldwide, drupal development, portfolio" title="ICON Worldwide portfolio, drupal development, Swiss Finance Institute"/>
            <div className="title"><h1>Swiss Finance Institute<span></span></h1>
            <h2>Drupal website development for Swiss financial services</h2>
            </div>
        </div>
        <div className="portfolio-inner">
        <p>The Swiss Finance Institute serves the financial services industry in Switzerland with a complete curriculum of Executive Education course and events. ICON developed the engaging website in Drupal.</p>
        <div className="portfolio-images">
            <img src={sfi1} alt="Swiss Finance Institute, ICON Worldwide, drupal development, portfolio" title="ICON Worldwide portfolio, drupal development, Swiss Finance Institute"/>
            <div></div>
            <img src={sfi3} alt="Swiss Finance Institute, ICON Worldwide, drupal development, portfolio" title="ICON Worldwide portfolio, drupal development, Swiss Finance Institute"/>
        </div>
        <img className="full-img" src={sfi2} alt="Swiss Finance Institute, ICON Worldwide, drupal development, portfolio" title="ICON Worldwide portfolio, drupal development, Swiss Finance Institute"/>
        <WorkFooter previous="saut" next="womens-football"/>
        </div>
    </div>
</div>